import React from "react";
import PropTypes from "prop-types";
import { ButtonStyled } from "./Button.styled";

const Button = ({ text, type, ...rest }) => {
  return <ButtonStyled {...{ text, type, ...rest }}>{text}</ButtonStyled>;
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default Button;