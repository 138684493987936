import styled from "styled-components";
import PreviewCompatibleImage from "../PreviewCompatibleImage";
export const AgeGateContainer = styled(PreviewCompatibleImage)`
  width: 100vw;
  min-height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  margin:0px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mediaSmall}) {
    align-items: unset !important;
  }
`;
export const AgeGateContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  position: relative;
  left: 5%;
  h2 {
    text-align: center;
    ${({ theme }) => theme.common.typography.heroMedium};
    font-weight: 300;
    text-transform: uppercase;
    font-style: normal;
    margin-left: 5%;
    margin-right: 5%;
  }
  .buttonContainer {
    display: flex;
    flex-direction: row;
    button {
      background: ${({ theme }) => theme.common.palette.darkGray};
      &:first-of-type {
        margin-right: 20px;
      }
    }
  }
  .logo {
    width: 160px;
    height: 160px;
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width: ${({ theme }) => theme.breakpoints.mediaSmall}) {
      width: 115px;
      height: 115px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.mediaSmall}) {
    h2 {
      ${({ theme }) => theme.common.typography.mediaSmall.heroMedium};
    }
    left: 0px;
    margin: 0px 25%;
  }
`;

export const BottomContent = styled.div`
  position: absolute;
  bottom: 0px;
  left: 10%;
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.common.palette.brown};
  h2 {
    margin-right: 20px;
  }
  .denote {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    max-width: 400px;
    @media (max-width: ${({ theme }) => theme.breakpoints.mediaSmall}) {
      font-size: 11px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.mediaSmall}) {
    h2 {
      margin-right: 0px;
    }
    display: block;
  }
`;
