import React from "react";
import { navigate } from "gatsby";
import {
  AgeGateContainer,
  AgeGateContent,
  BottomContent,
} from "./AgeGate.styled";
import Button from "../Buttons/Button";
import Cookie from "universal-cookie";
import PreviewCompatibleImage from "../PreviewCompatibleImage";
const cookie = new Cookie();

const ReplaceNewlinesWithbreaks = ({ data }) => {
  //console.log(data);
  const splitData = data.split("_n_");
  return (
    <>
      {splitData.map((split) => {
        return (
          <>
            {split}
            <br />
          </>
        );
      })}
    </>
  );
};
const AgeGate = ({ background, logo, heading, noredirect, bottom }) => {
  const handleYes = () => {
    cookie.set("kp-age-gate-accepted", true, { maxAge: 2678400 });
    navigate("/");
  };
  return (
    <AgeGateContainer loading="lazy" cover imageInfo={background || "" } Tag="section">
      <AgeGateContent>
        <div className="logo" loading="lazy">
          <PreviewCompatibleImage imageInfo={logo || "" } Tag="section" />
        </div>
        <h2>{heading}</h2>
        <div className="buttonContainer">
          <Button text={"Yes"} onClick={handleYes} />
          <a
            href={noredirect}
            rel="noopener noreferrer"
            style={{ margin: 0, padding: 0 }}
          >
            <Button text={"No"} style={{ marginRight: 0 }} />
          </a>
        </div>
      </AgeGateContent>
      <BottomContent>
        <h2>{bottom[0].slogan}</h2>
        <h5 className="denote">
          <ReplaceNewlinesWithbreaks data={bottom[0].denoter} />
        </h5>
      </BottomContent>
    </AgeGateContainer>
  );
};

export default AgeGate;
