import styled from 'styled-components';

const ButtonStyled = styled.button`
  padding: 13px 19px;
  background: ${(props) => props.theme.common.palette.red};
  color: ${(props) => props.theme.common.palette.white};
  border:none;
  text-transform:uppercase;
  border-radius: 3px;
  cursor:pointer;
  &:hover{
    transform:brightness(0.9);
  }
`;

export { ButtonStyled };
