import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { ThemeProvider } from "styled-components";
import Theme from "../theme/themeFile";
import AgeGateComponent from "../components/AgeGate/AgeGate";

const AgeGate = () => {
  return (
    <ThemeProvider theme={Theme}>
      <StaticQuery
        query={graphql`
          query AgeGateQuery {
            markdownRemark(frontmatter: { templateKey: { eq: "age-gate" } }) {
              frontmatter {
                logo {
                  childImageSharp {
                    fluid(maxWidth: 2048, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                  publicURL
                }
                background {
                  childImageSharp {
                    fluid(maxWidth: 2048, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                  publicURL
                }
                heading
                noredirect
                bottom {
                  slogan
                  denoter
                }
              }
            }
          }
        `}
        render={(data) => <AgeGateComponent {...data.markdownRemark.frontmatter} />}
      />
    </ThemeProvider>
  );
};

export default AgeGate;
